import React from 'react'

const Total = props => {
	const { total } = props
	return (
		<div className="pagination-total mb-2 sm:mb-0">
			Total <span>{total}</span> Items
		</div>
	)
}

export default Total
